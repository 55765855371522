@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply this to the background div with the class `parallax-bg` */
.parallax-bg {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
